<template>
    <c-panel>
        <div class="closed">
            <h1>Permanently Closed</h1>
            <p>
                Unfortunately, I've had to close the business. It has been a pleasure working with so many great artists
                and photographers, and I will truly miss seeing all of your amazing work!
            </p>
            <p>
                If you need a recommendation or want advice on how to do your own printing, art reproduction, or color
                management, you can contact me at my personal email: byfairweather@gmail.com
            </p>
        </div>
    </c-panel>
</template>

<script lang="ts">
export default {
    name: "Closed",
    setup() {
        return {};
    },
};
</script>

<style lang="scss">
.closed {
    margin: 0 auto;
    text-align: center;
    max-width: 550px;
    margin-top: 48px;

    h1 {
        font-size: 2.5rem;
    }
}
</style>
